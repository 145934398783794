<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row
      class="mb-7 fade-transition"
      justify="center"
      no-gutters
    >
      <v-col
        class="text-right"
        cols="10"
      >
        <v-btn
          class="ml-2"
          text
          color="success"
          @click="updateCustomers()"
        >
          Atualizar
          <v-divider
            class="mx-4"
            vertical
          />
          <v-icon>mdi-update</v-icon>
        </v-btn>
        <!-- <fullscreen-display
          :online="computedCustomersOnline"
          :offline="computedCustomersOffline"
        /> -->
        <v-text-field
          v-model="searchText"
          clearable
          clear-icon="mdi-close-circle-outline"
          class="px-3 py-3"
          counter="60"
          label="Buscar"
        />
        <v-row>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <base-material-card
              :title="`Offline: ${usersOffline}`"
              class="px-3 py-3"
              color="#D50000"
              icon="mdi-access-point-off"
            >
              <v-data-table
                :items="computedCustomersOffline"
                :headers="headers"
                :search="searchText"
                :page.sync="pageOffline"
                :items-per-page="perPageOffline"
                hide-default-footer
                height="535px"
                transition="fade-transition"
                :loading="loading"
              />
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pageOffline"
                  :length="pageCountOffline"
                  circle
                  total-visible="7"
                  color="#F44336"
                />
                <v-text-field
                  :value="perPageOffline"
                  label="Itens por página"
                  type="number"
                  min="-1"
                  @input="perPageOffline = parseInt($event, 10)"
                />
              </div>
            </base-material-card>
          </v-col>

          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <base-material-card
              :title="`Online: ${usersOnline}`"
              class="px-3 py-3"
              icon="mdi-access-point-check"
            >
              <v-data-table
                :items="computedCustomersOnline"
                :headers="headers"
                :search="searchText"
                :page.sync="pageOnline"
                :items-per-page="perPageOnline"
                hide-default-footer
                transition="fade-transition"
                height="535px"
                :loading="loading"
              />
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pageOnline"
                  :length="pageCountOnline"
                  circle
                  total-visible="7"
                  color="#4CAF50"
                />
                <v-text-field
                  :value="perPageOnline"
                  label="Itens por página"
                  type="number"
                  min="-1"
                  @input="perPageOnline = parseInt($event, 10)"
                />
              </div>
            </base-material-card>
          </v-col>
        </v-row>
        <p
          class="mr-5 md-5 text-right"
          origin="top right"
        >
          Última atualização: {{ pingStatistics.updatedAt }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  export default {
    name: 'Dashboard',
    components: {
      // FullscreenDisplay: () => import('./components/dashboard/fullscreenDisplay'),
    },
    data () {
      return {
        customersOnline: [],
        customersOffline: [],
        usersOnline: '',
        usersOffline: '',
        searchText: '',
        pageOnline: 1,
        pageOffline: 1,
        itemsPerPageOnline: 10,
        itemsPerPageOffline: 10,
        loading: false,
        firstLoad: true,
      }
    },
    computed: {
      ...mapGetters('customers', ['pingStatistics']),
      headers () {
        return [
          { text: 'Nome', value: 'name' },
        ]
      },
      computedCustomersOnline () {
        this.updateCustomersOnline()
        return this.customersOnline
      },
      computedCustomersOffline () {
        this.updateCustomersOffline()
        this.checkNotifications()
        return this.customersOffline
      },

      pageCountOnline () {
        return Math.ceil(this.customersOnline.length / (this.itemsPerPageOnline || 1))
      },
      pageCountOffline () {
        return Math.ceil(this.customersOffline.length / (this.itemsPerPageOffline || 1))
      },

      perPageOnline: {
        get: function () {
          return (this.itemsPerPageOnline || 1)
        },
        set: function (newValue) {
          if (newValue < 1 || typeof newValue !== 'number') {
            this.itemsPerPageOnline = 1
          } else {
            this.itemsPerPageOnline = newValue
          }
        },
      },

      perPageOffline: {
        get: function () {
          return (this.itemsPerPageOffline || 1)
        },
        set: function (newValue) {
          if (newValue < 1 || typeof newValue !== 'number') {
            this.itemsPerPageOffline = 1
          } else {
            this.itemsPerPageOffline = newValue
          }
        },
      },
    },
    async mounted () {
      await this.updateCustomers()
      this.firstLoad = false
      setInterval(() => { this.updateCustomers() }, 10 * 60 * 1000) // 10min
    },

    methods: {
      ...mapActions('customers', ['getCustomers']),
      ...mapActions('dashboard', ['addNotifications']),
      updateCustomersOnline () {
        const arrayConcat = []
        for (const index in this.pingStatistics.onlineCustomers) {
          arrayConcat.push({ name: this.pingStatistics.onlineCustomers[index].toUpperCase(), status: 'Online' })
        }
        this.usersOnline = this.pingStatistics.onlineCustomers.length
        this.customersOnline = arrayConcat
      },
      updateCustomersOffline () {
        const arrayConcat = []
        for (const index in this.pingStatistics.offlineCustomers) {
          arrayConcat.push({ name: this.pingStatistics.offlineCustomers[index].toUpperCase(), status: 'Offline' })
        }
        this.usersOffline = this.pingStatistics.offlineCustomers.length
        this.customersOffline = arrayConcat
      },
      checkNotifications () {
        let message = []
        if (this.usersOffline > 0 && !this.firstLoad) {
          message = ['Aviso! Clientes offline: ']
          message[0] += this.usersOffline
        }
        if (this.usersOnline === 0 && !this.firstLoad) {
          message.push('Aviso! Nenhum cliente Online!')
        }
        if (this.usersOnline === 0 && this.usersOffline === 0 && !this.firstLoad) {
          message.push('Nenhum cliente detectado! Verifique a API')
        }
        this.addNotifications(message)
      },
      async updateCustomers () {
        this.loading = true
        await this.getCustomers()
        this.updateCustomersOnline()
        this.updateCustomersOffline()
        this.checkNotifications()
        this.loading = false
      },
    },
  }
</script>
